// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10309(507c5acf71)-C09/09/2024-15:50:12-B09/09/2024-15:55:49' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10309(507c5acf71)-C09/09/2024-15:50:12-B09/09/2024-15:55:49",
  branch: "production",
  latestTag: "6.0",
  revCount: "10309",
  shortHash: "507c5acf71",
  longHash: "507c5acf71fbfb79b616481314454d1cf009945a",
  dateCommit: "09/09/2024-15:50:12",
  dateBuild: "09/09/2024-15:55:49",
  buildType: "Ansible",
  } ;
